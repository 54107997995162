import Vue from 'vue';
import VueRouter from "vue-router";
import Login from '../login/Login'
import NotFound from '../NotFound'
import About from '../about/About'
import Contact from '../contact/Contact'
import Privacy from '../privacy/Privacy'
import Reports from '../reports/Reports'
import Participate from '../participate/Participate'
import Benchmarking from '../benchmarking/Benchmarking'
import { authGuard } from '../plugins/auth0/authguard';
Vue.use(VueRouter);

export default new VueRouter({
  routes: [
    { path: '/benchmarking', label: 'Benchmarking', beforeEnter: authGuard, component: Benchmarking},
    { path: '/about', label: 'About SSIS', component: About},
    { path: '/contact', label: 'Contact', component: Contact},
    { path: '/privacy', label: 'Privacy', component: Privacy},
    { path: '/ssis-reports', label: 'SSIS Reports', component: Reports},
    { path: '/participate', label: 'Participate in the SSIS', component: Participate},
    { path: '/', visible: false, component: Login},
    { path: '*', visible: false, component: NotFound}
  ]
});