<template>
  <div class="container">
    <a class="logo" style="float:left" href="/" title="Swiss Software Industry Survey">
      <img class="img-responsive" src="./img/logo.png" />
    </a>
    <a
      class="logo"
      style="float:right"
      href="http://www.unibe.ch"
      title="Swiss Software Industry Survey"
    >
      <img class="img-responsive" src="./img/unibe_small.png" />
    </a>

    <br style="clear:both" />

    <Navigation></Navigation>
    <main>
      <router-view></router-view>
    </main>
  </div>
</template>
<script>
import Navigation from './nav/Navigation'
export default {
  components: {
    Navigation
  }
}
</script>

<style>
.cc_logo {
  display: none !important;
}

input.ng-dirty.ng-invalid {
  background-color: pink;
}

.logo {
  margin: 1em;
}

figure.report {
  border: 2px solid #ccc;
  padding: 1em;
}

figcaption {
  margin-top: 5px;
}

main {
  margin: 2rem 1rem;
}
</style>