<template>
  <nav class="navbar navbar-expand-lg navbar-light bg-light">
    <button
      class="navbar-toggler"
      type="button"
      data-toggle="collapse"
      data-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav mr-auto">
        <router-link
          v-for="link in routes.filter(d => d.visible !== false)"
          :key="link.path"
          :to="link.path"
          v-slot="{ href, route, navigate, isActive, isExactActive }"
        >
          <li class="nav-item" :class="{active: isExactActive}">
            <a class="nav-link" :href="href">
              {{link.label}}
            </a>
          </li>
        </router-link>
      </ul>
      <ul class="navbar-nav">
        <li v-if="$auth.isAuthenticated" @click="logout" style="cursor: pointer">Logout</li>
      </ul>
    </div>
  </nav>
</template>
<script>
export default {
  computed: {
    routes() {
      return this.$router.options.routes;
    }
  },
  methods: {
    // Log the user out
    logout() {
      this.$auth.logout({
        returnTo: window.location.origin
      });
    }
  },
  mounted() {
    console.log(this.$router);
  }
};
</script>
