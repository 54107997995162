<template>
  <div>
    <p>
      This benchmarking tool allows you to compare key performance indicators of
      your company with the industry average. Please select what indicator you
      want to compare and with whom.
    </p>
    <div class="row">
      <div class="col-md-6">
        <h2>Compare what?</h2>

        <select class="form-control" v-model="keyword" @change="reload()">
          <optgroup
            v-for="group in keywords"
            :key="group.key"
            :label="group.key"
          >
            <option
              v-for="option in group.values"
              :key="option.keyword"
              :value="option"
            >
              {{ option.label }}
            </option>
          </optgroup>
        </select>
      </div>

      <div class="col-md-6">
        <h2>with whom?</h2>

        <select class="form-control" v-model="subgroup" @change="reload()">
          <option :value="undefined">All</option>
          <optgroup
            v-for="group in subgroups"
            :key="group.key"
            :label="group.key"
          >
            <option
              v-for="option in group.values"
              :key="option.keyword"
              :value="option"
            >
              {{ option.label }}
            </option>
          </optgroup>
        </select>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <h3 class="text-center">{{ keyword.label }}</h3>
      </div>

      <div class="col-md-12 text-center" v-if="keyword.type == 'radar'">
        <div
          v-for="year in showYears"
          :key="year.label"
          class="form-check-inline"
        >
          <label>
            <input
              type="checkbox"
              v-model="year.show"
              @change="radarUpdate()"
            />
            {{ year.label }}
          </label>
        </div>
      </div>
      <div class="col-md-12" v-show="!loading">
        <canvas ref="bar" v-show="keyword.type !== 'radar'"></canvas>
        <canvas ref="radar" v-show="keyword.type === 'radar'"></canvas>
      </div>
    </div>
  </div>
</template>
<script>
import { Bar, Radar, Chart } from "chart.js";
import axios from "axios";
const keywords = require("../../common/keyword-options.json");
const subgroups = require("../../common/subgroup-options.json");

export default {
  data() {
    return {
      keywords: keywords
        .map(({ key, values }) => ({
          key,
          values: values.filter((d) => d.visible),
        }))
        .filter((d) => d.values.length > 0),
      keyword: keywords[0].values[0],
      subgroups,
      subgroup: undefined,
      showYears: [
        {
          label: 1942,
          show: true,
        },
      ],
      loading: false,
      radarData: undefined,
      barChart: undefined,
      radarChart: undefined,
    };
  },
  methods: {
    async reload() {
      try {
        this.loading = true;
        const accessToken = await this.$auth.getTokenSilently();
        const json = this.subgroup
          ? (
              await this.$axios.get(
                `/api/average/${this.keyword.keyword}/${this.subgroup.group}/${this.subgroup.value}`
              )
            ).data
          : (await this.$axios.get(`/api/average/${this.keyword.keyword}`))
              .data;
        this.loading = false;
        if (this.keyword.type === "radar") {
          this.radarChart.data = json;
          this.radarData = [...json.datasets];
          this.showYears = json.years.map((label) => ({ label }));
          this.showYears[this.showYears.length - 1].show = true;
          this.radarChart.options.scale = {
            ticks: {
              callback: (val) => this.format(val, 0) + " " + this.keyword.unit,
            },
          };
          this.radarChart.options.tooltips.callbacks = {
            title: (tooltipItem, data) => null,
            label: (tooltipItem, data) => {
              return data.datasets.map(
                (d) =>
                  `${data.labels[tooltipItem.index]} (${
                    d.label
                  }): ${this.format(d.data[tooltipItem.index])}${
                    this.keyword.unit
                  }`
              );
            },
          };
          this.radarUpdate();
        } else {
          this.barChart.data = json;

          this.barChart.options.scales.yAxes[0] = {
            ticks: {
              callback: (val) => this.format(val, 0) + " " + this.keyword.unit,
              suggestedMin: 0,
            },
          };

          this.barChart.options.scales.xAxes[0] = {
            ticks: {
              callback: (val) => val,
            },
          };

          this.barChart.options.tooltips.callbacks = {
            label: (tooltipItem, data) => {
              return (
                (tooltipItem.yLabel
                  ? this.format(tooltipItem.yLabel)
                  : tooltipItem.yLabel) +
                " " +
                this.keyword.unit
              );
            },
          };

          this.barChart.update();
        }
      } catch (err) {
        console.log(err);
      }
    },
    radarUpdate() {
      const years = this.showYears.filter((y) => y.show).map((d) => d.label);
      this.radarChart.data.datasets = this.radarData.filter((d) =>
        years.includes(+d.year)
      );
      this.radarChart.update();
    },
    format(num, fix = 2) {
      if (num) {
        const log = Math.log10(num);
        if (log > 9.2) {
          return (num / Math.pow(10, 9)).toFixed(fix) + " Mia. ";
        } else if (log > 6.2) {
          return (num / Math.pow(10, 6)).toFixed(fix) + " Mio. ";
        } else if (log > 3.2) {
          return (num / Math.pow(10, 3)).toFixed(fix) + " k ";
        } else {
          return num.toFixed(fix);
        }
      } else {
        return num;
      }
    },
  },
  mounted() {
    console.log("mounty");
    this.barChart = new Bar(this.$refs.bar.getContext("2d"), {
      options: {
        responsive: true,
        legend: {
          position: "top",
        },
      },
    });
    this.radarChart = new Radar(this.$refs.radar.getContext("2d"), {
      options: {
        responsive: true,
        legend: {
          position: "top",
        },
      },
    });
    this.reload();
  },
};
</script>
