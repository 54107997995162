import {Auth0Plugin} from './plugins/auth0';
import 'regenerator-runtime/runtime'
import Vue from 'vue';
import router from './routes';
import App from './App';

import 'bootstrap'
import 'bootstrap/dist/css/bootstrap'
import 'jquery'
import 'popper.js'
import 'chartjs-plugin-colorschemes';

import {domain, clientId} from './auth0.conf.json';

// Install the authentication plugin here
Vue.use(Auth0Plugin, {
  domain,
  clientId,
  audience: 'https://softwareindustrysurvey.ch/api',
  onRedirectCallback: appState => {
    window.history.replaceState({}, document.title, '/#/benchmarking');
    router.push(
      appState && appState.targetUrl
        ? appState.targetUrl
        : '/benchmarking'
    );
  }
});

new Vue({
  el: document.body.appendChild(document.createElement('div')),
  router,
  Auth0Plugin,
  render: r => r(App)
});