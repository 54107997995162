<template>
  <div>
    <div class="row">
      <div class="col-md-4"></div>
      <div class="col-md-4">
        <div class="panel panel-primary">
          <div class="panel-heading">
            <h3 class="panel-title">
              Welcome to the Swiss Software Industry Survey
            </h3>
          </div>
          <div class="panel-body">
            <div>
              <button
                type="button"
                class="btn btn-primary"
                v-if="!$auth.isAuthenticated"
                @click="login"
              >
                Goto Benchmarking (login required)
              </button>
            </div>
            <div>
              <router-link to="/participate"
                >Register to participate in the survey</router-link
              >
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4"></div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
export default {
  methods: {
    login() {
      this.$auth.loginWithRedirect();
    },
    mounted() {
      if (this.$auth.isAuthenticated) {
        this.$router.push("/benchmarking");
      }
    },
  },
  computed: {
    isAuthenticated() {
      console.log("desu");
      return this.$auth.isAuthenticated;
    },
  },
  watch: {
    isAuthenticated(loggedIn) {
      this.$router.push("/benchmarking");
    },
  },
};
</script>