<template>
  <div>
    <h1>Participate in the SSIS</h1>

    <p>Welcome to the Swiss Software Industry Survey (SSIS) participant registration.</p>
    <p>Please fill in the blanks if you wish to participate with your company in the next inquiry.</p>
    <p>Best regards, The SSIS Team</p>

    <div class="row">
      <div class="col-md-6">
        <form name="participation" ref="form">
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label for="firstname">First name (*)</label>
                <input
                  class="form-control"
                  type="text"
                  id="firstname"
                  name="firstname"
                  v-model="participant.firstname"
                  required
                />
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label for="lastname">Last name (*)</label>
                <input
                  class="form-control"
                  type="text"
                  id="lastname"
                  name="lastname"
                  v-model="participant.lastname"
                  required
                />
              </div>
            </div>
          </div>

          <div class="form-group">
            <label for="email">Email (*)</label>
            <input
              class="form-control"
              type="email"
              id="email"
              name="email"
              v-model="participant.email"
              placeholder="john.doe@example.net"
              required
            />
          </div>
          <div class="form-group">
            <label for="company">Company (*)</label>
            <input
              class="form-control"
              type="text"
              id="company"
              name="company"
              v-model="participant.company"
              required
            />
          </div>
          <div class="form-group">
            <label for="website">Website</label>
            <input
              class="form-control"
              type="text"
              id="website"
              name="website"
              v-model="participant.website"
            />
          </div>
          <div class="form-group">
            <label for="phone">Phone number</label>
            <input
              class="form-control"
              type="text"
              id="phone"
              name="phone"
              v-model="participant.phone"
            />
          </div>

          <button
            class="btn btn-default btn-primary"
            :disabled="!participant.firstname || !participant.lastname || !participant.email || !participant.company"
            @click.prevent="submit($event)"
          >Submit</button>
          <div
            v-if="message"
            class="alert"
            :class="message.messageClass"
            role="alert"
          >{{message.text}}</div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      participant: {},
      message: undefined
    };
  },
  methods: {
    async submit(ev, participation) {
      if (this.$refs.form.reportValidity()) {
        try {
          await axios.post("/participate", this.participant);
          this.message = {
            messageClass: "alert-success",
            text: "Participation request successfully sent!"
          };
        } catch (err) {
          this.message = {
            messageClass: "alert-error",
            text: "There was an error sending the request."
          };
        }
      }
    }
  }
};
</script>