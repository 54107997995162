<template>
  <div>
    <div class="container">
      <h3>Project lead</h3>

      <div class="row">
        <div class="col-md-6 vcard well">
          <div class="row">
            <div class="col-md-4">
              <a href="https://www.unibe.ch">
                <img class="img-responsive" style="max-width: 80%" alt="Universität Bern" src="../img/unibe.png" />
              </a>
            </div>
            <div class="col-md-8">
              <p class="fn">
                <a class="url" href="https://www.iwi.unibe.ch">
                  University of Berne
                  <br />Institute for Information Engineering
                </a>
              </p>
              <p></p>
              <p class="adr">
                <span class="name">Prof. Dr. Jens Dibbern</span>
                <br />
                <span class="street-address">Engehaldenstrasse 8</span>
                <br />
                <span class="postal-code">3012</span>
                <span class="region">Bern</span>
                <br />
                <span class="country-name">Switzerland</span>
              </p>

              <p class="tel">+41 (0)31 684 38 89</p>
            </div>
          </div>
        </div>
      </div>
      <hr />
      <h3>Principal supporter</h3>

      <div class="row">
        <div class="col-md-6 vcard well">
          <div class="row">
            <div class="col-md-4">
              <a href="https://www.swico.ch">
                <img class="img-responsive" style="max-width: 80%" alt="Swico"
                  src="https://www.swico.ch/static/img/logo-swico.2d7a4e034a86.svg" />
              </a>
            </div>
            <div class="col-md-8">
              <p class="fn">
                <a class="url" href="https://www.swico.ch">
                  Swico - der Wirtschaftsverband für die ganze Schweiz
                </a>
              </p>
              <p></p>
              <p class="adr">
                <span class="street-address">Lagerstrasse 33</span>
                <br />
                <span class="postal-code">8004</span>
                <span class="region">Zürich</span>
                <br />
                <span class="country-name">Switzerland</span>
              </p>

              <p class="tel">+41 (0)44 446 90 90</p>
            </div>
          </div>
        </div>
      </div>

      <hr />

      <h3>Operational Partners</h3>
      <div class="row">
        <div class="col-md-6 vcard well">
          <div class="row">
            <div class="col-md-4">
              <a href="https://www.sieberpartners.com">
                <img class="img-responsive" style="max-width: 80%"
                  src="https://static1.squarespace.com/static/5109428de4b04ea0ec18ef88/t/5b3de79d88251b5631c6f65d/1604915554718/?format=1500w" />
              </a>
            </div>
            <div class="col-md-8">
              <p class="fn">
                <a class="url" href="https://www.sieberpartners.com">
                  Dr. Pascal Sieber &amp; Partners AG
                </a>
              </p>
              <p></p>
              <p class="adr">
                <span class="street-address">Schwanengasse 1</span>
                <br />
                <span class="postal-code">3011</span>
                <span class="region">Bern</span>
                <br />
                <span class="country-name">Switzerland</span>
              </p>

              <p class="tel">+41 (0)31 566 93 00</p>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <a href="https://www.iwsb.ch">
                <img class="img-responsive" style="max-width: 80%" src="https://www.iwsb.ch/images/Logo_IWSB.png" />
              </a>
            </div>
            <div class="col-md-8">
              <p class="fn">
                <a class="url" href="https://www.iwsb.ch">
                  Institut für Wirtschaftsstudien Basel
                </a>
              </p>
              <p></p>
              <p class="adr">
                <span class="street-address">Gilgenberstrasse 21</span>
                <br />
                <span class="postal-code">4053</span>
                <span class="region">Basel</span>
                <br />
                <span class="country-name">Switzerland</span>
              </p>

              <p class="tel">+41 (0)61 281 21 21</p>
            </div>
          </div>
        </div>
      </div>

      <hr />

      <h3>Design &amp; Implementation &amp; Hosting</h3>
      <div class="row">
        <div class="col-md-6 vcard well">
          <div class="row">
            <div class="col-md-4">
              <a href="https://tran-engineering.ch">
                <img class="img-responsive" style="max-width: 80%" alt="Tran Engineering"
                  src="../../../static/img/tran-engineering.png" />
              </a>
            </div>
            <div class="col-md-8">
              <p class="fn">
                <a class="url" href="https://tran-engineering.ch">
                  tran engineering
                </a>
              </p>
              <p></p>
              <p class="adr">
                <span class="name">Khôi Tran</span>
                <br />
                <span class="street-address">Balmholzweg 12</span>
                <br />
                <span class="postal-code">3145</span>
                <span class="region">Niederscherli</span>
                <br />
                <span class="country-name">Switzerland</span>
              </p>

              <p class="tel">+41 (0)79 572 64 47</p>
            </div>
          </div>
        </div>
      </div>

      <hr />

      <h3>Advertising Partners</h3>
      <div class="image-group">
        <a :href="entry.url" v-for="entry in advertisingPartners" :key="entry.url">
          <img :src="entry.img" />
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    premiumSupporters: () => [
    ],
    supporters: () => [

    ],
    advertisingPartners: () => [
      {
        img:
          "https://alpict.ch/uploads/Izsja.fe7f336e05d9acf067c211deccf5f29e.svg",
        url: "https://alpict.ch/home",
      },
      {
        img:
          "https://www.topsoft.ch/media/1061/topsoft_rgb.png?width=160&height=50",
        url: "https://www.topsoft.ch",
      },
      {
        img:
          "/img/Netzwoche.png",
        url: "https://www.netzwoche.ch/",
      },
    ],
    other: () => [
      {
        img:
          "https://www.alpict.com/wp-content/uploads/2017/01/LOGO_AlpICT_TechNation.png",
        url: "https://www.alpict.com",
      },
      {
        img:
          "https://www.inside-it.ch/immutableAssets/assets/img/logos/insideit/26597387c77ffeb294b04dc4e29295e7/logo.svg",
        url: "https://www.inside-it.ch",
      },
      {
        img:
          "https://www.swico.ch/static/img/logo-swico.2d7a4e034a86.svg",
        url: "https://www.swico.ch",
      },
      {
        img:
          "https://www.topsoft.ch/media/1061/topsoft_rgb.png?width=160&height=50",
        url: "https://www.topsoft.ch",
      },
    ],
  },
};
</script>

<style lang="scss" scoped>
.image-group {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-evenly;

  img {
    max-width: 160px;
    min-width: 160px;
    margin: 2em;
  }
}
</style>